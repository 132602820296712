import React, { useState, useCallback, useEffect, Fragment } from 'react'

import * as ipom from '../common.jsx'
import Error from '../Error.jsx'

import * as utils from './collectionUtils.jsx'

const CollectionWantlist = ({
    itemType, userInfo, multiPageType, loaded, setLoaded
}) => {
    const [error, setError] = useState(false)
    const [options, setOptions] = useState({
        'descriptions': true,
        'incomplete': true,
        'noimages': true,
        'sort_reverse': false,
        'max': 36,
    })
    const [varietyMap, setVarietyMap] = useState(null)
    const [varietyOptions, setVarietyOptions] = useState({})

    console.log("Rendering ItemWantList, mpt", multiPageType)

    const genWantUrl = () => {
        console.debug('genWantUrl: entered')
        let args = [`item_type=${itemType}`]
        for (const [input, val] of Object.entries(options)) {
            const prefName = `include_${input.replace(' ', '_')}`
            let urlKey, urlVal
            if (input === 'max') {
                urlKey = input
                urlVal = val
            } else {
                urlKey = input === 'sort_reverse' ? input : prefName
                urlVal = val ? 'y' : 'n'
            }
            args.push(`${urlKey}=${urlVal}`)
        }
        for (const [input, val] of Object.entries(varietyOptions)) {
            const prefName = utils.varietyToPref(input, itemType)
            args.push(`${prefName}=${val ? 'y' : 'n'}`)
        }
        console.debug(args)
        return `/cgi-bin/collections_gen_wantlist.pl?${args.join('&')}`
    }

    const handleOptionsChange = (e) => {
        console.debug('handleOptionsChange: entered')
        console.debug(e)
        let newOpts = {...options}
        if (e.target.id === 'max') {
            let newVal = e.target.value
            if (newVal > 36) {
                newVal = 36
            } else if (newVal < 1) {
                newVal = 1
            }
            newOpts[e.target.id] = newVal
            /*
             * not a controlled element, see the comment
             * in optionCheckboxes()
             */
            e.target.value = newVal
        } else {
            newOpts[e.target.id] = e.target.checked
        }
        console.debug(newOpts)
        setOptions(newOpts)
    }

    const handleVarietiesChange = (e) => {
        console.debug('handleVarietiesChange: entered')
        let newOpts = {...varietyOptions}
        newOpts[e.target.id] = e.target.checked
        console.debug('newOpts: ', newOpts)
        setVarietyOptions(newOpts)
    }

    const optionCheckboxes = () => {
        return Object.keys(options).map(i => {
            if (i === 'max') {
                return (<Fragment key="max">
                    <input id="max"
                        size="1"
                        maxLength="2"
                        type="input"
                        onBlur={handleOptionsChange}
                        /*
                         * This is not a controlled input because
                         * otherwise our enforcement of min/max
                         * in handleOptionsChange means you can't
                         * delete a value to add a new one, it
                         * forces back in 1... so we make it uncontrolled,
                         * and only act onBlur, when focus leaves,
                         * and if we need to override the velue, we write
                         * it back
                         */
                        defaultValue={options[i]} /> Max (up to 36)<br/>
                </Fragment>)
            } else {
                let txt = `Include ${i}`
                if (i === 'noimages') {
                    txt = `Include ${itemType}s w/out images`
                } else if (i === 'sort_reverse') {
                    txt = 'Reverse sort order'
                }
                return (<Fragment key={i}>
                    <input id={i}
                        type='checkbox'
                        onChange={handleOptionsChange}
                        checked={options[i]} />{txt}<br/>
                </Fragment>)
            }
        })
    }

    const varietyCheckboxes = () => {
        console.debug("varietyCheckboxes: entered")
        if (!varietyMap) {
            return
        }
        let arr = []
        for (const cat of ['Official', 'Semi-Official']) {
            arr.push(<Fragment key={cat}><strong>{cat}</strong><br/></Fragment>)
            for (const v of varietyMap[cat]) {
                arr.push(<Fragment key={v}>
                    <input id={v}
                        type='checkbox'
                        onChange={handleVarietiesChange}
                        checked={varietyOptions[v]} />
                    {utils.varietyToHeading(v)}<br />
                </Fragment>)
            }
            arr.push(<br key={`${cat}-br`} />)
        }
        return arr
    }

    const getVarieties = useCallback(({itemType}) => {
        console.debug('getVarieties: entered')
        let url = '/cgi-bin/collections_varieties_values.pl'
        let url_args = `item_type=${itemType}`
        fetch(`${ipom.API_ENDPOINT}${url}?${url_args}`)
            .then(response => response.json())
            .then(i => {
                console.log(i)
                if (i['error']) {
                    setError(i['error_message'])
                    return
                }
                /*
                 * pagetype -> variety
                 */
                setVarietyMap(i['results']['varieties'])
                /*
                 * Walk that and make variety -> pref
                 */
                let prefs = {}
                for (const vars of Object.values(i['results']['varieties'])) {
                    for (const v of vars) {
                        prefs[v] = true
                    }
                }
                setVarietyOptions(prefs)
                setLoaded(true)
            })
            .catch(err => console.error('Failed to get varieties:', err))
    }, [setLoaded, setVarietyOptions])

    useEffect(() => {
        console.debug("Running effect to get varities info")
        if (multiPageType) {
            getVarieties({...{itemType}})
        } else {
            setLoaded(true)
        }
    }, [itemType, setLoaded, multiPageType, getVarieties])

    if (error) {
        return <Error {...{error}} />
    }

    return (<>
<p>
Below is an image with all picks you need, if we have images for them. To save, right-click on the image and choose &quot;Save Image As...&quot;</p>

<div id="wantlist" className="table-hscroll-container">
    <table width="100%">
        <tbody>
            <tr>
                <td align="center" width="85%">
                    <img id="wantlist" src={genWantUrl()} alt="Wantlist" />
                </td>

                <td valign="top">
                    <p>
                    <strong>Options</strong><br/>
                    {optionCheckboxes()}
                    </p>

                    {multiPageType && <p>{varietyCheckboxes()}</p>}
                </td>
            </tr>
        </tbody>
    </table>
</div>
    </>)

}

export default CollectionWantlist
